import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

export default ({ pageContext: { article } }) => {
    return (
        <Layout>
            <Head title={article.title} />

            <div className="max-w-prose mx-auto">
                <div className="mb-8 mt-4">
                    <h1 className="font-extrabold text-4xl text-gray-900 mb-3">{article.title}</h1>
                    <p className="space-x-2">
                        <span className="font-medium text-gray-500">By Judith Glixon</span>
                        <span className="font-bold text-gray-300">/</span>
                        <time className="text-gray-500">{article.prettyDate}</time>
                    </p>
                </div>
                <div className="prose" dangerouslySetInnerHTML={{ __html: article.markdown }} />

                <Link className="text-gray-400 italic block mt-12" to="/articles/">&larr; Back to all articles</Link>
            </div>
        </Layout>
    )
}
